<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{
              $t("message.new_m", {
                m: $t("message.role_id"),
              })
            }}</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-row :gutter="20">
            <el-form ref="form" status-icon :model="form" :rules="rules">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <el-form-item
                    prop="name"
                    size="mini"
                    :label="$t('message.name')"
                  >
                    <el-input
                      :placeholder="$t('message.name')"
                      v-model="form.name"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <el-form-item
                    prop="slug"
                    size="mini"
                    :label="$t('message.slug')"
                  >
                    <el-input
                      :placeholder="$t('message.slug')"
                      v-model="form.slug"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <!-- end col -->
              <el-col :span="24">
                <div class="app-form__group mb-4">
                  <el-form-item>
                    <span> {{$t('message.access_rights')}} </span>
                    <el-tree
                      :data="permissions"
                      show-checkbox
                      node-key="id"
                      ref="tree"
                      :default-expanded-keys="[]"
                      :default-checked-keys="[]"
                      :props="defaultProps"
                    >
                    </el-tree>
                  </el-form-item>
                </div>
              </el-col>
            </el-form>

            <!-- end col -->
          </el-row>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  computed: {
    ...mapGetters({
      rules: "roles/rules",
      model: "roles/model",
      permissions: "permissions/parents",
      columns: "roles/columns",
    }),
  },
  methods: {
    ...mapActions({
      allPermissions: "permissions/parents",
      save: "roles/store",
      empty: "roles/empty",
      emptyPerms: "permissions/empty",
    }),
     afterOpen() {
      this.allPermissions();
    },
    submit(close = true) {
      let checked = this.$refs.tree.getCheckedNodes();
      let permissionIds = [];
      checked.forEach((element) => {
        permissionIds.push(element.id);
      });
      this.form["permissions"] = permissionIds;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    afterLeave() {
      this.empty();
    },
  },
};
</script>
